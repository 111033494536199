// Vanilla Libraries
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'css.escape';
import Modernizr from 'modernizr'; // Import handled by modernizr-loader, so vendor.js must also have this line
import moment from 'moment';
import 'moment-timezone';

import {Swiper, Navigation, Pagination, Autoplay, EffectFade} from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

import * as Sentry from '@sentry/browser';
Sentry.init({
  dsn: sentryDsn,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
});

// JQuery & Plugins
import 'jquery';
import 'jquery.dotdotdot';
import 'magnific-popup';
import Cookie from 'js-cookie';

// Beamstyle - Jquery Plugins
import 'jquery.ajaxrest';
import 'jquery.ajaxrestform';
import 'jquery.infinitescroll';

import Masonry from "masonry-layout";
const imagesLoaded = require("imagesloaded");

var jlib = {
  myCore: {
    initialize: function() {
      jlib.myCore.dateTime.initialize();
    },
    dateTime: {
      initialize: function() {
        var dateTimeFormat = 'YYYY-MM-DD HH:mm:ss',
          browserDateTimeOnLoad = moment()
            .tz(myCore.dateTime.serverTimezone)
            .format(dateTimeFormat);

        myCore.dateTime.browserDateTimeClockDrift = moment(myCore.dateTime.serverDateTimeOnLoad, dateTimeFormat).diff(
          browserDateTimeOnLoad,
          'seconds',
        );

        function refreshServerDateTimeNow() {
          var browserDateTimeNow = moment()
              .tz(myCore.dateTime.serverTimezone)
              .format(dateTimeFormat),
            timeElapsedSinceLoad = moment(browserDateTimeNow, dateTimeFormat).diff(browserDateTimeOnLoad, 'seconds');

          myCore.dateTime.serverDateTimeNow = moment(myCore.dateTime.serverDateTimeOnLoad, dateTimeFormat)
            .add(timeElapsedSinceLoad, 'seconds')
            .format(dateTimeFormat);
          setTimeout(refreshServerDateTimeNow, 1000);
        }
        refreshServerDateTimeNow();
      },
      methods: {
        getServerTimezone: function() {
          return myCore.dateTime.serverTimezone;
        },
        getServerDateTimeOnLoad: function() {
          return myCore.dateTime.serverDateTimeOnLoad;
        },
        getServerDateTimeNow: function() {
          return myCore.dateTime.serverDateTimeNow;
        },
        getBrowserDateTimeClockDrift: function() {
          return myCore.dateTime.browserDateTimeClockDrift;
        },
      },
    },
  },
  helpers: {
    link: function(href, target) {
      target = target === undefined ? '_self' : target;
      window.open(href, target);
    },
    getFilenameWithoutExtension: function(filename) {
      return filename.replace(/\.[^/.]+$/, '');
    },
  },
  ajaxRest: {
    initialize: function() {
      // Form for "edit"
      $('.ajax-edit').ajaxRestForm({
        // Passing in the RESPONSES_BY_STATUS_CODES
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,

        // Overriding some common callbacks.  We pass in "true" for recursive merge, and {} to ensure the objects we pass in do not get modified itself.
        callbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            jlib.ajaxRest.commonCallbacks.success(jqXHR);
            var $form = $(this),
              successRedirectUrl = $form.data('success-redirect');
            jlib.helpers.link(successRedirectUrl);
          },
        }),
      });

      // Form for "create"
      $('.ajax-create').ajaxRestForm({
        // Passing in the RESPONSES_BY_STATUS_CODES
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,

        // Overriding some common callbacks.  We pass in "true" for recursive merge, and {} to ensure the objects we pass in do not get modified itself.
        callbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            jlib.ajaxRest.commonCallbacks.success(jqXHR);
            var $form = $(this),
              successRedirectUrl = $form.data('success-redirect');
            jlib.helpers.link(successRedirectUrl);
          },
        }),
      });

      $(".ajax-submit-no-redirect").ajaxRestForm({
        // Passing in the RESPONSES_BY_STATUS_CODES
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,

        // Overriding some common callbacks.  We pass in "true" for recursive merge, and {} to ensure the objects we pass in do not get modified itself.
        callbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
          // Proper response callbacks
          success: function (jqXHR) {
            jlib.ajaxRest.commonCallbacks.success(jqXHR);
            var $form = $(this),
              message = $form.data("message-success");
            $form.find(".response").removeClass("fail hide").text(message);
            $form.find("input, textarea").val("");
            $form.find(".field.user_title select").val("mr");
            setTimeout(function () {
              location.reload();
            }, 3000);
          },
          validationError: function (jqXHR) {
            var $form = $(this),
              data = jqXHR.responseJSON,
              errors = data.errors,
              message = $form.data("message-error");
            jlib.ajaxRest.helpers.displayErrors($form, errors);
            $form
              .find(".response")
              .addClass("fail")
              .removeClass("hide")
              .text(message);
          },
        }),
      });
    },
    helpers: {
      displayErrors: function($form, errors) {
        $.each(errors, function(field, message) {
          var $field = $form.find('.field.' + CSS.escape(field, { isIdentifier: true })),
            $errorMessage = $field.find('.error-message');
          $errorMessage.text(message);
          $field.addClass('error');
        });
      },
    },
    commonCallbacks: {
      // Before AJAX request
      beforeSend: function(resolve, reject) {
        console.log('beforeSend');
        // $.LoadingOverlay('show');
        resolve();
      },

      // Connectivity error callbacks
      userAborted: function(jqXHR) {
        console.log('userAborted');
      },
      responseTimedOut: function(jqXHR) {
        console.log('responseTimedOut');
      },

      // Parser error callbacks
      parserError: function(jqXHR) {
        console.log('parserError');
      },

      // Proper response callbacks
      success: function(jqXHR) {
        console.log('success');
      },
      requestMalformed: function(jqXHR) {
        console.log('requestMalformed');
      },
      loginRequired: function(jqXHR) {
        console.log('loginRequired');
        var sessionTimedOutRedirectUrl =
          siteUrl + 'console/connect/login?redirectUrl=' + encodeURIComponent(currentRouteWithQueryString);
        jlib.helpers.link(sessionTimedOutRedirectUrl);
      },
      notEnoughAccess: function(jqXHR) {
        console.log('notEnoughAccess');
      },
      notFound: function(jqXHR) {
        console.log('notFound');
      },
      unsupportedFormat: function(jqXHR) {
        console.log('unsupportedFormat');
      },
      validationError: function(jqXHR) {
        var $form = $(this),
          data = jqXHR.responseJSON,
          errors = data.errors;
        jlib.ajaxRest.helpers.displayErrors($form, errors);
      },
      tooManyRequests: function(jqXHR) {
        console.log('tooManyRequests');
      },
      unexpectedError: function(jqXHR) {
        console.log('unexpectedError');
      },
      default: function(jqXHR) {
        console.log('default');
      },
    },
  },
};

$(document).ready(function(e) {
  jlib.myCore.initialize();
  jlib.ajaxRest.initialize();

  // Initialize multiline truncate (ellipsis / clamp) effect
  $('.multiline-truncate').each(function() {
    var $text = $(this),
      maximumLines = parseInt($text.data('maximum-lines')),
      lineHeight = parseInt($text.css('line-height'));

    if (!isNaN(maximumLines)) {
      $text.css('max-height', lineHeight * maximumLines);
      $text.css('overflow', 'hidden');
    }

    $text.dotdotdot({
      ellipsis: '...',
      watch: 'window',
    });
  });

  // Infinite Scroll
  $('.infinite-scroll').infiniteScroll({
    ajaxRestCallbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
      success: function(jqXHR) {
        jlib.ajaxRest.commonCallbacks.success(jqXHR);
        console.log('ajaxRestCallbacks.success', $(this), jqXHR);
      },
    }),
    callbackEntityAdded: function($entity) {
      console.log('callbackEntityAdded', $(this), $entity);
    },
  });

  // Block Carousel
  $('.carousel').each(function() {
    var $blockCarousel = $(this),
      $blockCarousel_swiper = $blockCarousel.find('.swiper-container'),
      $blockCarousel_pagination = $blockCarousel.find('.pagination'),
      $blockCarousel_navigation = $blockCarousel.find('.navigation'),
      $blockCarousel_navigation_buttonPrevious = $blockCarousel_navigation.find('.button-previous'),
      $blockCarousel_navigation_buttonNext = $blockCarousel_navigation.find('.button-next');

    var loop = $blockCarousel.data('loop'),
      autoplay = $blockCarousel.data('autoplay'),
      breakpoints = $blockCarousel.data('breakpoints'),
      effect = $blockCarousel.data('effect'),
      disableOnInteraction = $blockCarousel.data('disable-on-interaction');

    var swiperOptions = {
      direction: 'horizontal',
      effect: effect !== undefined ? effect : false,
      loop: loop !== undefined ? loop : false,
    };

    // If autoplay is defined and not false
    if (autoplay !== undefined && autoplay) {
      swiperOptions = $.extend(swiperOptions, {
        autoplay: {
          disableOnInteraction: disableOnInteraction !== undefined ? disableOnInteraction : true,
          delay: parseInt(autoplay),
        },
      });
    }

    // If breakpoints is defined
    if (breakpoints !== undefined) {
      swiperOptions = $.extend(swiperOptions, {
        breakpoints: breakpoints[0],
      });
    }

    // If there are navigation elements (< and >)
    if ($blockCarousel_navigation.length) {
      swiperOptions = $.extend(swiperOptions, {
        navigation: {
          prevEl: $blockCarousel_navigation_buttonPrevious[0],
          nextEl: $blockCarousel_navigation_buttonNext[0],
        },
      });
    }

    // If there is a pagination element (bullets)
    if ($blockCarousel_pagination.length) {
      swiperOptions = $.extend(swiperOptions, {
        pagination: {
          el: $blockCarousel_pagination[0],
          clickable: true,
        },
      });
    }

    var mySwiper = new Swiper($blockCarousel_swiper[0], swiperOptions);
  });

  $(".google-map").each(function () {
    var $this = $(this),
      el = $this.get(0),
      latitude = $this.data("latitude"),
      longitude = $this.data("longitude"),
      zoom = $this.data("zoom"),
      image = $this.data("image");
    // console.log(latitude);
    // console.log(longitude);
    // console.log(zoom);
    var map = new google.maps.Map(el, {
      center: { lat: latitude, lng: longitude },
      zoom: zoom,
      scrollwheel: false,
    });

    var marker = new google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map: map,
      icon: image,
    });
  });

  $('.lightbox-trigger').each(function () {
    let $this = $(this);
    let $id = $this.attr('href');
    let closeOnBgClick = $this.data('close-on-bg-click') !== undefined ? $this.data('close-on-bg-click') : 'true';
    let showCloseBtn = $this.data('show-close-btn') !== undefined ? $this.data('show-close-btn') : 'true';
    let enableEscapeKey = $this.data('enable-escape-key') !== undefined ? $this.data('enable-escape-key') : 'true';
    let fixedContentPos = $this.data('fixed-content-pos') !== undefined ? $this.data('fixed-content-pos') : 'auto';
    let fixedBgPos = $this.data('fixed-bg-pos') !== undefined ? $this.data('fixed-bg-pos') : 'auto';
    let autoPopup = $this.data('auto-popup') !== undefined ? $this.data('auto-popup') : false;
    let autoPopupIgnoreDuration = $this.data('auto-popup-ignore-duration') !== undefined ? $this.data('auto-popup-ignore-duration') : 0;
    let expireDatetime;

    if ($.isNumeric(autoPopupIgnoreDuration) === true && autoPopupIgnoreDuration > 0) {
      expireDatetime = new Date(new Date().getTime() + autoPopupIgnoreDuration * 60 * 1000); // Minutes
    }

    let config = {
      items: {
        src: $id,
        type: 'inline',
      },
      type: 'inline',
      closeOnBgClick: closeOnBgClick,
      showCloseBtn: showCloseBtn,
      enableEscapeKey: enableEscapeKey,
      fixedContentPos: fixedContentPos,
      fixedBgPos: fixedBgPos,
      callbacks: {
        open: function () {
          if (expireDatetime !== undefined) {
            Cookie.set('ignore-popup-' + $(this.content).attr('id'), true, {
              expires: expireDatetime,
            });
          }
          console.log($(this.content).find('.mfp-close'));
          $(this.content).find('.mfp-close').each(function() {
            let $this = $(this);
            $this.on('click', function(e) {
              e.preventDefault()
              console.log('test');
              // $.magnificPopup.close();
            });
          });
        },
        close: function () {
        },
      },
    };

    if (autoPopup == true) {
      let isClick = true;
      if (expireDatetime !== undefined) {
        let ignoreId = $this.data('ignore-id') !== undefined ? $this.data('ignore-id') : 0;
        let cookie = Cookie.get('ignore-popup-' + ignoreId);
        if (cookie === 'true') {
          isClick = false;
        }
      }

      if (isClick) {
        $.magnificPopup.open(config);
      }
    }

    $this.magnificPopup(config);
  });

  // init Masonry
  var $grid = new Masonry(".grid", {
    // options
    itemSelector: ".grid-item",
    gutter: 40,
    isFitWidth: true,
  });

  // layout Isotope after each image loads
  new imagesLoaded($grid).on("progress", function (instance) {
    $grid.masonry();
  });

  $(".mobile-menu ul li a").click(function(){

    var $mobile_menu = $("#mobile-menu"),
        $mobile_menu_topic_item = $(this).parent(),		//////ul li
        $mobile_menu_sub_list = $(this).siblings("ul"),	//////ul li ul
        $main = $("main"),
        numOfChild_in_sub_list = $mobile_menu_sub_list.children("li").length;   /////// ul li ul li
        ;

    $mobile_menu_topic_item.toggleClass("active");

    if($mobile_menu_topic_item.hasClass("active")){
        $mobile_menu_sub_list.css({"height":(42*numOfChild_in_sub_list )});
        $main.css({ "marginTop": $mobile_menu.height()+(42*numOfChild_in_sub_list ) });
    } else {
        $mobile_menu_sub_list.css({"height":"0px"});
        $main.css({ "marginTop": $mobile_menu.height()-(42*numOfChild_in_sub_list ) });
    }
  });

  $(".mobile-menu-button a").click(function(e) {
      e.preventDefault();

      var $mobile_menu = $("#mobile-menu"),
          $mobile_menu_button = $(this).parent(),
          $main = $("main"),
          mobile_menu_height = $mobile_menu.height();

      $mobile_menu_button.toggleClass("active");
      if($mobile_menu_button.hasClass("active")) {
          $main.addClass("mobile-menu-trigger");
          $main.css({ "marginTop": mobile_menu_height });
      } else {
          $main.css({ "marginTop": 0 });
      }
  });

  var mq = window.matchMedia('all and (max-width: 1152px)');

  mq.addListener(function(changed) {
      if(changed.matches) {

      } else {
          $("main").css({"marginTop": 0});

          if( $("#mobile-menu-button").hasClass("active") ) {
              $("#mobile-menu-button").removeClass("active");
          }
      }
  });

  // Click event to scroll to top
  $('.scroll-to-top').click(function(e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 400 }, 500);
  });

});
