import { createBrowserHistory } from 'history'
import Handlebars from 'handlebars';
import qs from 'qs';
import 'waypoints/lib/noframework.waypoints';

(function($, window, document) {
  const history = createBrowserHistory();

  $.fn.infiniteScroll = function(options) {
    options = $.extend(
      {
        ajaxRestCallbacks: {},
        callbackEntityAdded: null,
      },
      options,
    );

    this.each(function() {
      var $this = $(this),
        $paginators = $this.find('.paginator'),
        $paginator_previous = $paginators.filter('.paginator-previous'),
        $paginator_next = $paginators.filter('.paginator-next'),
        $entities = $this.find('.entities'),
        $entities_templateEntity = $entities.find('.template-entity'),
        templateEntity = Handlebars.compile($entities_templateEntity.html()),
        fixedHeaderHeight = parseInt($this.data('fixed-header-height')) || 0;

      $entities_templateEntity.remove();

      // URL Query String
      var queryString_methods = {
        getQueryString: function() {
          var queryString = qs.parse(history.location.search.slice(1));
          $.extend(queryString, {
            limit: parseInt(queryString.limit),
          });
          return queryString;
        },
        incrementOffset: function() {
          var queryString = queryString_methods.getQueryString();
          queryString.offset++;
          history.replace('?' + qs.stringify(queryString));
          return true;
        },
        decrementOffset: function() {
          var queryString = queryString_methods.getQueryString();
          queryString.offset--;
          history.replace('?' + qs.stringify(queryString));
          return true;
        },
      };

      // Entities
      var entities_methods = {
        createWaypoint: function($entity) {
          var waypoint = new Waypoint({
            element: $entity[0],
            handler: function(direction) {
              if (direction == 'up') {
                queryString_methods.decrementOffset();
              } else {
                // direction == 'down'
                queryString_methods.incrementOffset();
              }
            },
            offset: function() {
              return -this.element.clientHeight + fixedHeaderHeight;
            },
          });
          $entity.data('waypoint', waypoint);
          return waypoint;
        },
        destroyWaypoint: function($entity) {
          var waypoint = $entity.data('waypoint');
          waypoint.destroy();
        },
        prependEntity: function($entity) {
          // Do some calculations to ensure the user's scroll position does not change after prepending entities.
          var $firstEntity = $entities.children(':first').length != 0 ? $entities.children(':first') : $entities,
            myScrollPosition = $(document).scrollTop(), // My scroll position
            firstEntityOldPosition = $firstEntity.offset().top, // First entity's old position
            offsetFromFirstEntity = firstEntityOldPosition - myScrollPosition; // My scroll positon relative to the first entity

          // Prepend an entity
          $entities.prepend($entity);

          // Adjust the user's scroll position relative to the new position of what was "originally the first entity"
          var firstEntityNewPosition = $firstEntity.offset().top;
          $(document).scrollTop(firstEntityNewPosition - offsetFromFirstEntity);

          // Decrement the offset of the new entity since this is to be adjusted by Waypoint on creation automatically
          queryString_methods.decrementOffset();
          entities_methods.createWaypoint($entity, fixedHeaderHeight);

          // We need this due to the detached thread causing a delay by Waypoint's algorithms.
          setTimeout(function() {
            Waypoint.refreshAll();
          }, 100);
        },
        appendEntity: function($entity) {
          $entities.append($entity);
          entities_methods.createWaypoint($entity, fixedHeaderHeight);
        },
      };

      // Paginators
      var paginators_methods = {
        statusLoading: function($paginator) {
          $paginator.addClass('loading');
        },
        statusReady: function($paginator) {
          $paginator.removeClass('loading');
        },
        createWaypoint: function($paginator, paginatorType) {
          var waypointOptions = {
            element: $paginator[0],
          };

          if (paginatorType === 'up') {
            waypointOptions = $.extend(true, {}, waypointOptions, {
              handler: function(direction) {
                if (direction === 'up') {
                  paginators_methods.destroyWaypoint($paginator);
                  paginators_methods.statusLoading($paginator);
                  $paginator.submit();
                }
              },
              offset: function() {
                // When bottom of element hits top of viewport
                return -this.element.clientHeight;
              },
            });
          } else if (paginatorType === 'down') {
            waypointOptions = $.extend(true, {}, waypointOptions, {
              handler: function(direction) {
                if (direction === 'down') {
                  paginators_methods.destroyWaypoint($paginator);
                  paginators_methods.statusLoading($paginator);
                  $paginator.submit();
                }
              },
              offset: '100%', // When top of element hits bottom of viewport
            });
          }

          var waypoint = new Waypoint(waypointOptions);
          $paginator.data('waypoint', waypoint);
        },
        destroyWaypoint: function($paginator) {
          var waypoint = $paginator.data('waypoint');
          waypoint.destroy();
        },
        getOffset: function($paginator) {
          var $offset = $paginator.find('input[name="offset"]'),
            offset = parseInt($offset.val());
          return offset;
        },
        setOffset: function($paginator, offset) {
          var $offset = $paginator.find('input[name="offset"]'),
            offset = parseInt(offset) || 0;
          $offset.val(offset);
          return true;
        },
        getLimit: function($paginator) {
          var $limit = $paginator.find('input[name="limit"]'),
            limit = parseInt($limit.val());
          return limit;
        },
        setLimit: function($paginator, limit) {
          var $limit = $paginator.find('input[name="limit"]'),
            limit = parseInt(limit);
          $limit.val(limit);
          return true;
        },
      };

      // When form submission returns success, process the response data appropriately.
      $paginator_previous.ajaxRestForm({
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,
        callbacks: $.extend(true, {}, options.ajaxRestCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            // Callback ajaxRestCallbacks.success()
            if ($.isFunction(options.ajaxRestCallbacks.success)) {
              $.proxy(options.ajaxRestCallbacks.success, $this[0])(jqXHR);
            }

            // Declare some variables
            var oldLimit = paginators_methods.getLimit($paginator_previous),
              oldOffset = paginators_methods.getOffset($paginator_previous),
              newLimit = Math.min(oldLimit, oldOffset),
              newOffset = Math.max(oldOffset - oldLimit, 0),
              entities = jqXHR.responseJSON.entities.reverse();

            // Reverse and prepend the entities
            entities.forEach(function(data) {
              var renderedHtml = templateEntity(data),
                $entity = $(renderedHtml);
              entities_methods.prependEntity($entity);

              // Callback callbackEntityAdded()
              if ($.isFunction(options.callbackEntityAdded)) {
                $.proxy(options.callbackEntityAdded, $this[0])($entity);
              }
            });

            // If what we get is less than our desired limit (for "paginator-next"), or if old offset is 0 (for "paginator-previous")
            if (entities.length < oldLimit || oldOffset == 0) {
              $paginator_previous.remove();
              return;
            }

            paginators_methods.setOffset($paginator_previous, newOffset);
            paginators_methods.setLimit($paginator_previous, newLimit);
            paginators_methods.statusReady($paginator_previous);
            paginators_methods.createWaypoint($paginator_previous, 'up');
          },
        }),
      });

      // When form submission returns success, process the response data appropriately.
      $paginator_next.ajaxRestForm({
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,
        callbacks: $.extend(true, {}, options.ajaxRestCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            // Callback ajaxRestCallbacks.success()
            if ($.isFunction(options.ajaxRestCallbacks.success)) {
              $.proxy(options.ajaxRestCallbacks.success, $this[0])(jqXHR);
            }

            // Declare some variables
            var oldLimit = paginators_methods.getLimit($paginator_next),
              oldOffset = paginators_methods.getOffset($paginator_next),
              newLimit = oldLimit,
              newOffset = oldOffset + oldLimit,
              entities = jqXHR.responseJSON.entities;

            // Append the entities
            entities.forEach(function(data) {
              var renderedHtml = templateEntity(data),
                $entity = $(renderedHtml);
              entities_methods.appendEntity($entity);

              // Callback callbackEntityAdded()
              if ($.isFunction(options.callbackEntityAdded)) {
                $.proxy(options.callbackEntityAdded, $this[0])($entity);
              }
            });

            if (entities.length < oldLimit) {
              $paginator_next.remove();
              return;
            }

            paginators_methods.setOffset($paginator_next, newOffset);
            paginators_methods.statusReady($paginator_next);
            paginators_methods.createWaypoint($paginator_next, 'down');
          },
        }),
      });

      // Create waypoint instances for each entity
      $entities.find('.entity').each(function() {
        var $entity = $(this);
        entities_methods.createWaypoint($entity, fixedHeaderHeight);
      });

      // When offset is not 0, we want to tweak the scroll position to that item
      if (queryString_methods.getQueryString().offset != 0) {
        // First we must disable the native scrollRestoration behavior by the browser
        window.history.scrollRestoration = 'manual';

        // If offset value on load is not zero, adjust the user's scroll position to the position of the first preloaded entity, and resolve when that's done.
        var $firstEntity = $entities.children(':first').length != 0 ? $entities.children(':first') : $entities,
          entitiesScrollPosition = $firstEntity.offset().top;

        $(document).scrollTop(entitiesScrollPosition);
      }

      // Initialize the valid paginators by creating waypoints, and remove invalid paginators
      $paginators.each(function() {
        var $paginator = $(this),
          $paginator_limit = $paginator.find('input[name="limit"]'),
          limit = parseInt($paginator_limit.val()),
          directionTrigger = $paginator.data('direction-trigger');

        if (limit == 0) {
          $paginator.remove();
        } else {
          // Initialize the paginator if it is valid
          paginators_methods.createWaypoint($paginator, directionTrigger);
        }
      });
    });

    return this;
  };
})(jQuery, window, document);
